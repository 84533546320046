




















































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import adminService from "@/services/AdminService";
import { OrganisationDetails } from "@/models/OrganisationDetails";
import { organisationModule } from "@/store/modules/OrganisationModule";
import { IConversionFactorType } from "@/models/ConversionFactorType";
import {
  IConversionFactor,
  IConversionFactorCreateRequest,
  IConversionFactorDeleteRequest,
} from "@/models/ConversionFactor";
import Utils from "@/common/utils/utils";

@Component
export default class ConversionFactorType extends Vue {
  @Prop() public conversionFactorType!: IConversionFactorType;
  @Prop() public percentage!: boolean;
  @Prop() public validScopes!: number[];

  public async mounted() {
    await this.getConversionFactors();
    this.getDisabledEmissionTypeIds();
  }

  get utils() {
    return Utils;
  }

  public get organisation(): OrganisationDetails {
    return organisationModule.organisation;
  }

  public disabledEmissionTypeIds: string[] = [];

  private getDisabledEmissionTypeIds() {
    this.disabledEmissionTypeIds = this.organisation.emissionTypes
      .filter((emissionType) => !Boolean(emissionType.unit))
      .map((emissionType) => emissionType.id);
  }

  get currentFactorIsDisabled() {
    return this.disabledEmissionTypeIds.includes(this.factor.emissionTypeId);
  }

  public factors: IConversionFactor[] = [];
  private submitted = false;

  public factor: IConversionFactorCreateRequest = {
    organisationUnitId: this.organisation.id,
    conversionFactorTypeId: this.conversionFactorType.id,
    fromYear: new Date().getFullYear(),
    fromMonth: new Date().getMonth() + 1,
    value: 0,
    source: "",
    sourceUrl: "",
    emissionTypeId: null,
  };

  get emissionTypeOptions() {
    return this.organisation.emissionTypes
      .filter((emissionType) => this.validScopes.includes(emissionType.scope))
      .map((emissionType) => {
        return {
          value: emissionType.id,
          text: Boolean(emissionType.unit)
            ? `${emissionType.name} (${emissionType.unit})`
            : emissionType.name,
        };
      });
  }

  get monthOptions() {
    return [
      { value: 1, text: "Januari" },
      { value: 2, text: "Februari" },
      { value: 3, text: "Mars" },
      { value: 4, text: "April" },
      { value: 5, text: "Maj" },
      { value: 6, text: "Juni" },
      { value: 7, text: "Juli" },
      { value: 8, text: "Augusti" },
      { value: 9, text: "September" },
      { value: 10, text: "Oktober" },
      { value: 11, text: "November" },
      { value: 12, text: "December" },
    ];
  }

  get minValue() {
    return 0;
  }

  get maxValue() {
    return this.percentage ? 100 : null;
  }

  get emissionTypeState() {
    if (!this.submitted) return null;
    return Boolean(this.factor.emissionTypeId);
  }

  get yearState() {
    if (!this.submitted) return null;
    return Boolean(this.factor.fromYear);
  }

  get monthState() {
    if (!this.submitted) return null;
    return Boolean(this.factor.fromMonth);
  }

  get valueState() {
    if (!this.submitted) return null;

    if (typeof this.factor.value === "string") return false;

    return Boolean(this.maxValue)
      ? this.factor.value >= this.minValue && this.factor.value <= this.maxValue
      : this.factor.value >= this.minValue;
  }

  get valueInvalidFeedback() {
    return Boolean(this.maxValue)
      ? `Värdet måste vara mellan ${this.minValue} och ${this.maxValue}`
      : `Värdet måste vara ${this.minValue} eller högre`;
  }

  get sourceState() {
    if (!this.submitted) return null;
    return Boolean(this.factor.source);
  }

  get sourceUrlState() {
    if (!this.submitted) return null;

    try {
      new URL(this.factor.sourceUrl);
    } catch (error) {
      return false;
    }

    return true;
  }

  public onSubmit() {
    this.submitted = true;
    if (
      this.emissionTypeState &&
      this.yearState &&
      this.monthState &&
      this.valueState &&
      this.sourceState &&
      this.sourceUrlState
    ) {
      this.createConversionFactor();
    }
  }

  public async createConversionFactor() {
    await adminService.createConversionFactor(
      this.organisation.id,
      this.factor
    );
    await this.getConversionFactors();
  }

  public async deleteConversionFactor(factor: IConversionFactorDeleteRequest) {
    if (confirm("Are you sure?")) {
      await adminService.deleteConversionFactor(this.organisation.id, factor);
    }

    await this.getConversionFactors();
  }

  private async getConversionFactors() {
    this.factors = await adminService.getConversionFactors(
      this.organisation.id,
      this.conversionFactorType.id
    );
  }

  get factorTableFields(): {
    key: keyof IConversionFactor | "delete";
    label: string;
  }[] {
    return [
      { key: "fromYear", label: "År" },
      { key: "fromMonth", label: "Månad" },
      { key: "emissionType", label: "Emissionstyp" },
      { key: "source", label: "Källa" },
      { key: "value", label: "Faktor" },
      { key: "delete", label: "" },
    ];
  }
}
